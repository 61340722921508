import en_meta from "../../../pages/en/_meta.ts";
import en_guides_meta from "../../../pages/en/guides/_meta.ts";
import en_guides_octobot_advanced_usage_meta from "../../../pages/en/guides/octobot-advanced-usage/_meta.ts";
import en_guides_octobot_configuration_meta from "../../../pages/en/guides/octobot-configuration/_meta.ts";
import en_guides_octobot_developers_environment_meta from "../../../pages/en/guides/octobot-developers-environment/_meta.ts";
import en_guides_octobot_installation_meta from "../../../pages/en/guides/octobot-installation/_meta.ts";
import en_guides_octobot_interfaces_meta from "../../../pages/en/guides/octobot-interfaces/_meta.ts";
import en_guides_octobot_interfaces_telegram_meta from "../../../pages/en/guides/octobot-interfaces/telegram/_meta.ts";
import en_guides_octobot_interfaces_tradingview_meta from "../../../pages/en/guides/octobot-interfaces/tradingview/_meta.ts";
import en_guides_octobot_partner_exchanges_meta from "../../../pages/en/guides/octobot-partner-exchanges/_meta.ts";
import en_guides_octobot_partner_exchanges_ascendex_meta from "../../../pages/en/guides/octobot-partner-exchanges/ascendex/_meta.ts";
import en_guides_octobot_partner_exchanges_binance_meta from "../../../pages/en/guides/octobot-partner-exchanges/binance/_meta.ts";
import en_guides_octobot_partner_exchanges_bingx_meta from "../../../pages/en/guides/octobot-partner-exchanges/bingx/_meta.ts";
import en_guides_octobot_partner_exchanges_bitget_meta from "../../../pages/en/guides/octobot-partner-exchanges/bitget/_meta.ts";
import en_guides_octobot_partner_exchanges_bitmart_meta from "../../../pages/en/guides/octobot-partner-exchanges/bitmart/_meta.ts";
import en_guides_octobot_partner_exchanges_bybit_meta from "../../../pages/en/guides/octobot-partner-exchanges/bybit/_meta.ts";
import en_guides_octobot_partner_exchanges_coinex_meta from "../../../pages/en/guides/octobot-partner-exchanges/coinex/_meta.ts";
import en_guides_octobot_partner_exchanges_crypto_com_meta from "../../../pages/en/guides/octobot-partner-exchanges/crypto-com/_meta.ts";
import en_guides_octobot_partner_exchanges_gateio_meta from "../../../pages/en/guides/octobot-partner-exchanges/gateio/_meta.ts";
import en_guides_octobot_partner_exchanges_hollaex_meta from "../../../pages/en/guides/octobot-partner-exchanges/hollaex/_meta.ts";
import en_guides_octobot_partner_exchanges_htx_meta from "../../../pages/en/guides/octobot-partner-exchanges/htx/_meta.ts";
import en_guides_octobot_partner_exchanges_kucoin_meta from "../../../pages/en/guides/octobot-partner-exchanges/kucoin/_meta.ts";
import en_guides_octobot_partner_exchanges_mexc_meta from "../../../pages/en/guides/octobot-partner-exchanges/mexc/_meta.ts";
import en_guides_octobot_partner_exchanges_okcoin_meta from "../../../pages/en/guides/octobot-partner-exchanges/okcoin/_meta.ts";
import en_guides_octobot_partner_exchanges_okx_meta from "../../../pages/en/guides/octobot-partner-exchanges/okx/_meta.ts";
import en_guides_octobot_partner_exchanges_phemex_meta from "../../../pages/en/guides/octobot-partner-exchanges/phemex/_meta.ts";
import en_guides_octobot_script_docs_meta from "../../../pages/en/guides/octobot-script-docs/_meta.ts";
import en_guides_octobot_supported_exchanges_meta from "../../../pages/en/guides/octobot-supported-exchanges/_meta.ts";
import en_guides_octobot_tentacles_development_meta from "../../../pages/en/guides/octobot-tentacles-development/_meta.ts";
import en_guides_octobot_trading_modes_meta from "../../../pages/en/guides/octobot-trading-modes/_meta.ts";
import en_guides_octobot_usage_meta from "../../../pages/en/guides/octobot-usage/_meta.ts";
import en_investing_meta from "../../../pages/en/investing/_meta.ts";
import en_investing_tradingview_strategies_tutorials_meta from "../../../pages/en/investing/tradingview-strategies-tutorials/_meta.ts";
export const pageMap = [{
  data: en_meta
}, {
  name: "about",
  route: "/en/about",
  children: [{
    name: "index",
    route: "/en/about",
    frontMatter: {
      "description": "Who is behind OctoBot ? How to contact the team ? Meet the OctoBot team and learn more about the OctoBot community.",
      "sidebarTitle": "Index"
    }
  }]
}, {
  name: "blog",
  route: "/en/blog",
  children: [{
    name: "announcing-the-bitmart-and-octobot-partnership",
    route: "/en/blog/announcing-the-bitmart-and-octobot-partnership",
    frontMatter: {
      "title": "Announcing the BitMart and OctoBot partnership",
      "description": "Join the trade to earn campaign to automate your DCA, AI, TradingView, crypto baskets and grid strategies on BitMart using OctoBot",
      "tags": "Crypto, Trading, Exchange, Partnership, BitMart",
      "image": "/images/blog/announcing-the-bitmart-and-octobot-partnership/bitmart-and-octobot-partnership.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1725753600000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "automated-trading-bot",
    route: "/en/blog/automated-trading-bot",
    frontMatter: {
      "title": "Automated Trading Bot",
      "description": "Learn how automated trading bots can revolutionize your trading strategy. Discover key benefits, best practices, and top strategies to maximize profits while minimizing emotional decisions.",
      "tags": "Cryptocurrency, Trading, Bot",
      "image": "/images/blog/automated-trading-bot/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1731369600000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "best-ai-trading-bots",
    route: "/en/blog/best-ai-trading-bots",
    frontMatter: {
      "title": "5 Best AI Trading Bots",
      "description": "Discover the best AI trading bots. Compare top free and paid Artificial intelligence bots, their features, ease of use, and pricing.",
      "tags": "Cryptocurrency, Trading, Plans",
      "image": "/images/blog/best-crypto-trading-bots/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1715040000000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "best-crypto-trading-bots",
    route: "/en/blog/best-crypto-trading-bots",
    frontMatter: {
      "title": "10 Best Paid and Free Crypto Trading Bots",
      "description": "Discover the best crypto trading bots. Compare top free and paid bots, their features, ease of use, and pricing. Ideal for both beginners and experts in cryptocurrency trading.",
      "tags": "Cryptocurrency, Trading, Plans",
      "image": "/images/blog/best-crypto-trading-bots/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1702598400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "best-open-source-crypto-trading-bots",
    route: "/en/blog/best-open-source-crypto-trading-bots",
    frontMatter: {
      "title": "5 Best Open source Crypto Trading Bots",
      "description": "Discover the top-performing open-source crypto trading bots. Find the best solutions for efficient cryptocurrency trading.",
      "tags": "Cryptocurrency, Trading, Plans",
      "image": "/images/blog/best-open-source-crypto-trading-bots/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1702771200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "bingx-wheel-of-fortune-event",
    route: "/en/blog/bingx-wheel-of-fortune-event",
    frontMatter: {
      "title": "BingX Wheel of Fortune Event",
      "description": "Join the BingX Wheel of Fortune event to earn up to 1000 USDT simply by trading using OctoBot cloud or your own OctoBot trading robot",
      "tags": "Crypto, Trading, Exchange, Partnership, BingX, Event",
      "image": "/images/blog/bingx-wheel-of-fortune-event/bingx-and-octobot-wheel-of-fortune-event-with-usdt-to-earn.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1708992000000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "chatgpt-strategy-deep-dive",
    route: "/en/blog/chatgpt-strategy-deep-dive",
    frontMatter: {
      "title": "ChatGPT trading strategy deep dive",
      "description": "Discover how we created the ChatGPT based OctoBot trading strategy by combining the benefits of AI and smart DCA strategies",
      "tags": "ChatGPT, AI, DCA, Strategy designer, Backtesting, Cryptocurrency, Trading, OctoBot cloud, Educational",
      "image": "/images/blog/chatgpt-strategy-deep-dive/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1703030400000),
      "searchable": false,
      "difficulty_level": 3,
      "reading_time": 10
    }
  }, {
    name: "cloud-octobot-plans",
    route: "/en/blog/cloud-octobot-plans",
    frontMatter: {
      "title": "OctoBot cloud plans",
      "description": "Explore the different OctoBot plans designed to suit every need, whether you seek simplicity or customization in your trading strategies.",
      "tags": "Cryptocurrency, Trading, Plans, OctoBot cloud",
      "image": "/images/blog/introducing-cloud-octobot-plans/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1698019200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "coinbase-and-binance.us-trading-bot",
    route: "/en/blog/coinbase-and-binance.us-trading-bot",
    frontMatter: {
      "title": "Coinbase and Binance.us Trading Bots",
      "description": "Automate your crypto investments using crypto baskets, DCA, AI, Grid or TradingView on Coinbase and Binance.us with OctoBot Cloud.",
      "tags": "Cryptocurrency, Trading, Exchange, Binance.us, Coinbase",
      "image": "/images/blog/coinbase-and-binance.us-trading-bot/binance.us-and-coinbase-support-on-octobot.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1713225600000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "crypto-bubble",
    route: "/en/blog/crypto-bubble",
    frontMatter: {
      "title": "Understanding the Crypto Bubble",
      "description": "Discover the exciting world of cryptocurrencies! Learn how to identify bubbles and keep your investments safe",
      "tags": "Crypto, Ecosystem, Finance, Educational",
      "image": "/images/blog/fomo-meaning/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1714608000000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "first-blog-post",
    route: "/en/blog/first-blog-post",
    frontMatter: {
      "title": "Welcome to the OctoBot blog",
      "description": null,
      "tags": "Crypto, Trading, Python, OctoBot",
      "image": "/images/blog/welcome-to-octobot-blog/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": "2022-06",
      "searchable": false,
      "archived": true
    }
  }, {
    name: "following-strategies-in-octobot-cloud",
    route: "/en/blog/following-strategies-in-octobot-cloud",
    frontMatter: {
      "title": "Following strategies in OctoBot cloud",
      "description": "You can now follow trading strategies of the community",
      "tags": "Cryptocurrency, Trading, Strategy, Exchange, OctoBot cloud",
      "image": "/images/blog/following-strategies-in-octobot-cloud/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": "2022-11",
      "searchable": false,
      "archived": true
    }
  }, {
    name: "fomo-meaning",
    route: "/en/blog/fomo-meaning",
    frontMatter: {
      "title": "Understanding the FOMO meaning",
      "description": "Explore FOMO meaning in crypto. Understand how Fear of Missing Out influences trading decisions and learn effective strategies to combat it.",
      "tags": "Crypto, Trading, Educational",
      "image": "/images/blog/fomo-meaning/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1702339200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "fud-meaning",
    route: "/en/blog/fud-meaning",
    frontMatter: {
      "title": "Understanding the FUD meaning",
      "description": "Explore the FUD meaning in crypto with this concise guide to understanding its influence on markets and investment decisions",
      "tags": "Crypto, Trading, Educational",
      "image": "/images/blog/fud-meaning/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1701907200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "hodl-meaning",
    route: "/en/blog/hodl-meaning",
    frontMatter: {
      "title": "Understanding the HODL meaning",
      "description": "Explore this famous word origins, meaning and cultural impact.",
      "tags": "Crypto, Trading, Educational",
      "image": "/images/blog/hodl-meaning/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1701907200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "hollaex-partnership",
    route: "/en/blog/hollaex-partnership",
    frontMatter: {
      "title": "OctoBot is now supporting Hollaex",
      "description": "OctoBot partners with Hollaex, the first decentralized crypto exchange platform.",
      "tags": "Crypto, Trading, Exchange, Partnership, Hollaex",
      "image": "/images/blog/hollaex-partnership/cover.jpg",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1656633600000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 1
    }
  }, {
    name: "how-does-trading-bot-work",
    route: "/en/blog/how-does-trading-bot-work",
    frontMatter: {
      "title": "How does trading bot work",
      "description": "Discover how does trading bot work, their benefits and drawbacks, and learn how to select the right bot for your trading needs.",
      "tags": "Crypto, Trading, Backtesting, Educational",
      "image": "/images/blog/how-does-trading-bot-work/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1701475200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "how-to-automate-trading-in-tradingview",
    route: "/en/blog/how-to-automate-trading-in-tradingview",
    frontMatter: {
      "title": "How to automate trading in TradingView",
      "description": "Automate your trades and strategies on any exchange directly from TradingView with webhook or emails. Trade with the best strategies and increase your gains.",
      "tags": "Crypto, Trading, Educational, TradingView",
      "image": "/images/blog/how-to-automate-trading-in-tradingview/automate-your-tradingview-trades-to-trade-on-any-indicator-or-strategy.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1727308800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 4
    }
  }, {
    name: "how-to-create-your-tradingview-strategy-with-ai",
    route: "/en/blog/how-to-create-your-tradingview-strategy-with-ai",
    frontMatter: {
      "title": "How to create your TradingView strategy with AI",
      "description": "Create and trade with your best trading strategies on TradingView using AI. Describe your strategy with your own words, improve it and invest with it.",
      "tags": "Crypto, Trading, Educational, TradingView, AI",
      "image": "/images/blog/how-to-create-your-tradingview-strategy-with-ai/trading-strategy-with-ai-in-5-minutes-using-octobot-cloud-and-free-tradingview-account.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1731110400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "introducing-chatgpt-trading-tool",
    route: "/en/blog/introducing-chatgpt-trading-tool",
    frontMatter: {
      "title": "Introducing ChatGPT crypto predictions",
      "description": "Discover how to use ChatGPT to predict the next crypto price movement. This article highlights our tool for tracking the latest predictions and our Twitter and Telegram accounts for real-time notifications.",
      "tags": "Chatgpt, Cryptocurrency, Trading, Strategy, AI, GPT",
      "image": "/images/blog/introducing-chatgpt-trading-tool/chatgpt-logo.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1699228800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "introducing-octobot-for-business",
    route: "/en/blog/introducing-octobot-for-business",
    frontMatter: {
      "title": "Introducing OctoBot for Business",
      "description": "Your tool to automate liquidity management in crypto markets. Improve trading efficiency with market making bots.",
      "tags": "Crypto, Trading, Market Making, Business",
      "image": "/images/blog/introducing-octobot-for-business/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1731024000000),
      "searchable": false,
      "difficulty_level": 2,
      "reading_time": 4
    }
  }, {
    name: "introducing-the-investor-plus-plan",
    route: "/en/blog/introducing-the-investor-plus-plan",
    frontMatter: {
      "title": "Introducing the Investor Plus plan",
      "description": "Discover the new Investor Plus plan and invest with every OctoBot strategy and crypto baskets you want, use more OctoBots and enjoy other benefits",
      "tags": "Cryptocurrency, Trading, OctoBot cloud, Release",
      "image": "/images/blog/introducing-the-investor-plus-plan/octobot-investor-plus-plan-announcement-with-tradingview-automations.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1708646400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "introducing-the-new-octobot-cloud",
    route: "/en/blog/introducing-the-new-octobot-cloud",
    frontMatter: {
      "title": "Introducing the new OctoBot cloud",
      "description": "OctoBot cloud, a new way to profit from trading strategies",
      "tags": "Free, Cryptocurrency, Trading, Strategy, Exchange, OctoBot cloud",
      "image": "/images/blog/introducing-the-new-octobot-cloud/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1695081600000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "introducing-the-new-octobot-mobile-app",
    route: "/en/blog/introducing-the-new-octobot-mobile-app",
    frontMatter: {
      "title": "Introducing the new OctoBot App",
      "description": "Discover the new OctoBot Android App. Track your OctoBots profits, assets, and balance in real-time from your mobile.",
      "tags": "Android, Mobile, OctoBot cloud",
      "image": "/images/blog/introducing-the-new-octobot-mobile-app/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1699574400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "introducing-the-pro-plan",
    route: "/en/blog/introducing-the-pro-plan",
    frontMatter: {
      "title": "Introducing the Pro plan",
      "description": "Discover the new Pro plan and fine-tune your OctoBot strategies, automate your trades from TradingView, configure your crypto baskets and more.",
      "tags": "Cryptocurrency, Trading, OctoBot cloud, Release",
      "image": "/images/blog/introducing-the-pro-plan/octobot-trading-plan-announcement-with-TradingView-automations-and-advanced-coins-trading.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1710892800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "introducing-the-strategy-designer",
    route: "/en/blog/introducing-the-strategy-designer",
    frontMatter: {
      "title": "Introducing the Strategy Designer",
      "description": "Meet the new Strategy Designer and get the best out of your strategies introducing-the-strategy-designer",
      "tags": "Strategy designer, Backtesting, Cryptocurrency, Trading, OctoBot cloud",
      "image": "/images/blog/introducing-the-strategy-designer/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1679529600000),
      "searchable": false,
      "difficulty_level": 3,
      "reading_time": 5
    }
  }, {
    name: "introducing-trading-modes-guides",
    route: "/en/blog/introducing-trading-modes-guides",
    frontMatter: {
      "title": "Introducing trading modes guides",
      "description": "Discover the multiple ways to trade with with OctoBot using trading modes based on DCA, grid trading, AI and TradingView introducing-trading-modes-guides",
      "tags": "Trading, Educational",
      "image": "/images/blog/introducing-trading-modes-guides/person-looking-at-his-screens-using-many-trading-strategies.jpg",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1704240000000),
      "searchable": false,
      "difficulty_level": 2,
      "reading_time": 3
    }
  }, {
    name: "invest-with-crypto-baskets",
    route: "/en/blog/invest-with-crypto-baskets",
    frontMatter: {
      "title": "Invest in crypto easily with crypto baskets",
      "description": "Discover a new way to invest and easily diversify your crypto portfolio with theme-based baskets",
      "tags": "Cryptocurrency, Trading, OctoBot cloud, Release",
      "image": "/images/blog/invest-with-crypto-baskets/crypto-basket.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1713139200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "making-octobot-more-accessible",
    route: "/en/blog/making-octobot-more-accessible",
    frontMatter: {
      "title": "Making OctoBot more accessible",
      "description": "OctoBot plans have been improved. They are not more accessible, contain more features and add many new possibilities.",
      "tags": "Cryptocurrency, Trading, Plans",
      "image": "/images/blog/making-octobot-more-accessible/octobot-plans-improvements.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1718668800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 4
    }
  }, {
    name: "mobile-app-revamp",
    route: "/en/blog/mobile-app-revamp",
    frontMatter: {
      "title": "New Design of the Mobile App",
      "description": "A new version of the Android and iPhone OctoBot mobile app is available, allowing you to better track your investments",
      "tags": "Mobile, App, OctoBot cloud, Release",
      "image": "/images/blog/mobile-app-revamp/thumb.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1713484800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "new-octobot-cloud-plans-and-trading-bots",
    route: "/en/blog/new-octobot-cloud-plans-and-trading-bots",
    frontMatter: {
      "title": "New OctoBot cloud plans",
      "description": "OctoBot cloud is releasing updated cloud plans to automate your crypto trading strategies and invest easily.",
      "tags": "AI, Backtesting, Cryptocurrency, Trading, OctoBot cloud, Release",
      "image": "/images/blog/new-octobot-cloud-plans-and-trading-bots/a-man-relaxing-in-his-couch-while-octobot-is-making-money-by-automating-cryptocurrency-strategies-light.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1707004800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "octobot-1-0-2-whats-new",
    route: "/en/blog/octobot-1-0-2-whats-new",
    frontMatter: {
      "title": "OctoBot 1.0.2 - What's new",
      "description": "Discover what's new in OctoBot - Chatgpt strategy upgrade, improved TradingView integration and more octobot-1-0-2-whats-new",
      "tags": "Tradingview, Chatgpt, Release, DCA, Backtesting",
      "image": "/images/blog/octobot-1-0-2-whats-new/cover.png",
      "domain": "octobot.cloud",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1698624000000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "octobot-1-0-4-whats-new",
    route: "/en/blog/octobot-1-0-4-whats-new",
    frontMatter: {
      "title": "OctoBot 1.0.4 - What's new",
      "description": "Discover what's new in OctoBot - Download OctoBot cloud strategies on your OctoBot, trade on BingX, use the improved Daily and DCA trading modes and more octobot-1-0-4-whats-new",
      "tags": "OctoBot cloud, Release, DCA, Exchanges",
      "image": "/images/blog/octobot-1-0-4-whats-new/with-octobot-1.0.4-use-octobot-cloud-strategies-and-trade-on-bingx.png",
      "domain": "octobot.cloud",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1702166400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "octobot-2-0-0-whats-new",
    route: "/en/blog/octobot-2-0-0-whats-new",
    frontMatter: {
      "title": "OctoBot 2.0.0 - What's new",
      "description": "OctoBot 2.0.0 improves OctoBot with a full redesign of the user interface and includes the Premium OctoBot extension.",
      "tags": "Tradingview, Chatgpt, Release, Backtesting, Strategy designer, OctoBot cloud",
      "image": "/images/blog/octobot-2-0-0-whats-new/octobot-2.0.0-annoucement-with-new-design-preview.png",
      "domain": "octobot.cloud",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1720310400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "octobot-pro-plan-early-access",
    route: "/en/blog/octobot-pro-plan-early-access",
    frontMatter: {
      "title": "OctoBot cloud trading bots early access",
      "description": "The OctoBot cloud trading bots are now in early access. Enjoy the Strategy Designer, seamless TradingView and ChatGPT integrations to create the best strategies octobot-pro-plan-early-access",
      "tags": "Strategy designer, AI, Backtesting, Cryptocurrency, Trading, OctoBot cloud, Release",
      "image": "/images/blog/octobot-pro-plan-early-access/octobot-pro-plan-early-access-announcement.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1701216000000),
      "searchable": false,
      "difficulty_level": 2,
      "reading_time": 5,
      "archived": true
    }
  }, {
    name: "octobots-in-octobot-cloud",
    route: "/en/blog/octobots-in-octobot-cloud",
    frontMatter: {
      "title": "Easily deploy your OctoBot in OctoBot cloud",
      "description": "You can now easily deploy your OctoBot directly in OctoBot cloud deploy-octobots-in-octobot-cloud",
      "tags": "Cryptocurrency, Trading, Strategy, OctoBot cloud",
      "image": "/images/blog/octobots-in-octobot-cloud/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1670716800000),
      "searchable": false,
      "archived": true
    }
  }, {
    name: "one-click-cloud-deployment-with-octobot-1-0-9",
    route: "/en/blog/one-click-cloud-deployment-with-octobot-1-0-9",
    frontMatter: {
      "title": "One Click Cloud Deployment with OctoBot 1.0.9",
      "description": "OctoBot 1.0.9 is released ! Deploy your OctoBot from the DigitalOcean marketplace, create your custom crypto baskets, use the improved TradingView Trading Mode",
      "tags": "Tradingview, Hosting, Release",
      "image": "/images/blog/one-click-cloud-deployment-with-octobot-1-0-9/octobot-1.0.9-ditigtalocean-1-click-deployment-custom-crypto-baskets.png",
      "domain": "octobot.cloud",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1713398400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "open-source-trading-software",
    route: "/en/blog/open-source-trading-software",
    frontMatter: {
      "title": "The Power of Open Source Trading Software",
      "description": "Understand the benefits of open source trading software. open-source-trading-software",
      "tags": "Open source, Cryptocurrency, Trading, Software, Educational",
      "image": "/images/blog/open-source-trading-software/cover.png",
      "domain": "octobot.cloud",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1697587200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "paper-trading-with-octobot",
    route: "/en/blog/paper-trading-with-octobot",
    frontMatter: {
      "title": "Paper trading with OctoBot",
      "description": "Test trading strategies risk free with paper trading. Run OctoBot strategies for free with simulated money. paper-trading-with-octobot",
      "tags": "Trading, Strategy, OctoBot cloud",
      "image": "/images/blog/paper-trading-with-octobot/cover.jpg",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1700179200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "profile-sharing-in-octobot-cloud",
    route: "/en/blog/profile-sharing-in-octobot-cloud",
    frontMatter: {
      "title": "Profile sharing in OctoBot cloud",
      "description": "You can now share your OctoBot profiles with the community profile-sharing-in-octobot-cloud",
      "tags": "Cryptocurrency, Trading, Exchange, OctoBot cloud",
      "image": "/images/blog/profile-sharing-in-octobot-cloud/cover.jpg",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1664496000000),
      "searchable": false,
      "archived": true
    }
  }, {
    name: "safu-meaning",
    route: "/en/blog/safu-meaning",
    frontMatter: {
      "title": "Understanding the SAFU meaning",
      "description": "Explore the origins, purpose, cultural impact of SAFU and particularly in its role in safeguarding user funds. safu-meaning",
      "tags": "Crypto, Trading, Educational",
      "image": "/images/blog/safu-meaning/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1701734400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 2
    }
  }, {
    name: "shape-the-future-with-our-roadmap",
    route: "/en/blog/shape-the-future-with-our-roadmap",
    frontMatter: {
      "title": "Shape the future of our roadmap",
      "description": "Vote for what matters to you and influence the future of OctoBot shape-the-future-with-our-roadmap",
      "tags": "Roadmap, Vote, Share, Cryptocurrency, OctoBot cloud",
      "image": "/images/blog/shape-the-future-with-our-roadmap/banner-dark.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1696032000000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "smart-dca-making-of",
    route: "/en/blog/smart-dca-making-of",
    frontMatter: {
      "title": "Smart DCA trading strategies making of",
      "description": "Discover how we created the Smart DCA trading strategies in OctoBot cloud, from the basics on choosing traded coins to the technical details of order sizing and take profit targets smart-dca-making-of",
      "tags": "DCA, Strategy designer, Backtesting, Cryptocurrency, Trading, OctoBot cloud, Educational",
      "image": "/images/blog/smart-dca-making-of/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1698796800000),
      "searchable": false,
      "difficulty_level": 3,
      "reading_time": 10
    }
  }, {
    name: "strategy-designer-revamp",
    route: "/en/blog/strategy-designer-revamp",
    frontMatter: {
      "title": "Strategy Designer Revamp",
      "description": "Enjoy the revamped Strategy Designer and created your best trading strategies strategy-designer-revamp",
      "tags": "Strategy designer, Configuration, Backtesting, Cryptocurrency, OctoBot cloud",
      "image": "/images/blog/strategy-designer-revamp/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1682380800000),
      "searchable": false,
      "difficulty_level": 3,
      "reading_time": 5
    }
  }, {
    name: "trading-on-coinex-with-octobot",
    route: "/en/blog/trading-on-coinex-with-octobot",
    frontMatter: {
      "title": "Trading on CoinEx with OctoBot",
      "description": "OctoBot has just partners with CoinEx to enable crypto traders to easily automate any trading strategy on CoinEx.",
      "tags": "Crypto, Trading, Exchange, Partnership, CoinEx",
      "image": "/images/blog/trading-on-coinex-with-octobot/trading-on-coinex-with-octobot.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1707091200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "trading-orders",
    route: "/en/blog/trading-orders",
    frontMatter: {
      "title": "The basics of trading - The orders",
      "description": "Read this article and get a basic understanding of the orders available. trading-orders",
      "tags": "Crypto, Trading, Automation, Orders, Educational",
      "image": "/images/blog/trading-orders/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1660089600000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "trading-strategy",
    route: "/en/blog/trading-strategy",
    frontMatter: {
      "title": "What is a trading strategy",
      "description": "Discover what is a trading strategy and why you should automate it. trading-strategy-automation",
      "tags": "Crypto, Trading, Strategy, Automation, Educational",
      "image": "/images/blog/trading-strategy-automation/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1655510400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "trading-using-chat-gpt",
    route: "/en/blog/trading-using-chat-gpt",
    frontMatter: {
      "title": "Trading using ChatGPT",
      "description": "Use ChatGPT to boost your crypto trading strategies with real-time market views and predictions trading-using-chat-gpt",
      "tags": "ChatGPT, AI, Cryptocurrency, Trading, OctoBot cloud",
      "image": "/images/blog/trading-using-chat-gpt/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1698883200000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 3
    }
  }, {
    name: "trading-using-tradingview",
    route: "/en/blog/trading-using-tradingview",
    frontMatter: {
      "title": "Trading using TradingView",
      "description": "Automate your trades using any TradingView indicator trading-using-tradingview",
      "tags": "Tradingview, Pine Script, Webhook, Strategy, OctoBot cloud, Educational",
      "image": "/images/blog/trading-using-tradingview/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1684281600000),
      "searchable": false,
      "difficulty_level": 2,
      "reading_time": 5
    }
  }, {
    name: "trading-with-ai-introduction",
    route: "/en/blog/trading-with-ai-introduction",
    frontMatter: {
      "title": "Crypto trading using artificial intelligence",
      "description": "Learn how to automate your crypto trading with AI in 5 steps trading-with-ai-introduction",
      "tags": "AI, Deep learning, Trading, Cryptocurrency, OctoBot, Educational",
      "image": "/images/blog/trading-with-ai-introduction/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1696636800000),
      "searchable": false,
      "difficulty_level": 2,
      "reading_time": 10
    }
  }, {
    name: "what-are-octobot-rewards-and-how-to-get-them",
    route: "/en/blog/what-are-octobot-rewards-and-how-to-get-them",
    frontMatter: {
      "title": "What are OctoBot rewards and how to get them?",
      "description": "OctoBot rewards enable you to unlock paid plan features for free by using OctoBot, being an active user and inviting friends.",
      "tags": "Rewards, OctoBot cloud, Release",
      "image": "/images/blog/what-are-octobot-rewards-and-how-to-get-them/octobot-rewards-get-rewarded-for-using-octobot-and-use-advanced-strategies.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1718668800000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "what-are-stablecoins",
    route: "/en/blog/what-are-stablecoins",
    frontMatter: {
      "title": "What are stablecoins",
      "description": "Explore the stablecoins, the crypto designed for stability in a volatile market. Understand the different types, their benefits, risks, and potential future impact on global finance. what-are-stablecoins",
      "tags": "Cryptocurrency, Trading, Educational",
      "image": "/images/blog/what-are-stablecoins/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1699142400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 10
    }
  }, {
    name: "what-is-dca",
    route: "/en/blog/what-is-dca",
    frontMatter: {
      "title": "DCA - One of the most profitable strategies",
      "description": "Dollar cost averaging is one of the most profitable strategies for a long term investment what-is-dca",
      "tags": "Cryptocurrency, Trading, Strategy, How-to, DCA, Educational",
      "image": "/images/blog/what-is-dca/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1663632000000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 5
    }
  }, {
    name: "what-is-future-trading",
    route: "/en/blog/what-is-future-trading",
    frontMatter: {
      "title": "What is future trading",
      "description": "Discover the world of cryptocurrency future trading and how to automate it in few steps. what-is-future-trading",
      "tags": "Cryptocurrency, Trading, Exchange, Future, Strategy, Educational",
      "image": "/images/blog/what-is-future-trading/cover.png",
      "domain": "blog.octobot.online",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1666656000000),
      "searchable": false,
      "difficulty_level": 2,
      "reading_time": 5
    }
  }, {
    name: "what-is-spot-trading",
    route: "/en/blog/what-is-spot-trading",
    frontMatter: {
      "title": "What is Spot Trading",
      "description": "Learn the ins and outs of spot trading and start building wealth in the cryptocurrency market. Discover the benefits, strategies, and best practices for successful spot trading.",
      "tags": "Cryptocurrency, Trading, Educational",
      "image": "/images/blog/what-is-spot-trading/cover.png",
      "sidebar": false,
      "breadcrumb": false,
      "date": new Date(1731542400000),
      "searchable": false,
      "difficulty_level": 1,
      "reading_time": 10
    }
  }]
}, {
  name: "blog",
  route: "/en/blog",
  frontMatter: {
    "searchable": false,
    "description": "Follow regular guides, updates and stories on OctoBot. Profit the most of your crypto investment strategies robot by being the first traders to discover our latest tips and future plans.",
    "title": "The OctoBot Blog, follow the latest news"
  }
}, {
  name: "guides",
  route: "/en/guides",
  children: [{
    data: en_guides_meta
  }, {
    name: "developers",
    route: "/en/guides/developers",
    frontMatter: {
      "description": "Wondering how to deeply customize OctoBot ? Learn how to start as an OctoBot developer, create your own tentacles and contribute to the open source trading robot",
      "sidebarTitle": "Developers"
    }
  }, {
    name: "exchanges",
    route: "/en/guides/exchanges",
    frontMatter: {
      "description": "Discover the many exchanges supported by OctoBot. Trade on Binance, OKX, Kucoin, Bybit, Crypto.com, HTX, Coinbase, Bitget, HollaEx, BingX, MEXC and many more.",
      "sidebarTitle": "Exchanges"
    }
  }, {
    name: "octobot-advanced-usage",
    route: "/en/guides/octobot-advanced-usage",
    children: [{
      data: en_guides_octobot_advanced_usage_meta
    }, {
      name: "beta-program",
      route: "/en/guides/octobot-advanced-usage/beta-program",
      frontMatter: {
        "description": "Join the OctoBot beta tester program and get early access to new features.",
        "sidebarTitle": "Beta Program"
      }
    }, {
      name: "tentacle-manager",
      route: "/en/guides/octobot-advanced-usage/tentacle-manager",
      frontMatter: {
        "description": "Learn how to install new tentacles (extensions) on your OctoBot using the tentacles manager.",
        "sidebarTitle": "Tentacle Manager"
      }
    }, {
      name: "using-octobot-with-a-proxy",
      route: "/en/guides/octobot-advanced-usage/using-octobot-with-a-proxy",
      frontMatter: {
        "description": "Use an http or https proxy for your OctoBot connect to your crypto exchange account from a specific IP address or location.",
        "sidebarTitle": "Using Octobot with a Proxy"
      }
    }]
  }, {
    name: "octobot-configuration",
    route: "/en/guides/octobot-configuration",
    children: [{
      data: en_guides_octobot_configuration_meta
    }, {
      name: "accounts",
      route: "/en/guides/octobot-configuration/accounts",
      frontMatter: {
        "description": "Learn OctoBot handles your exchange accounts and setup its web and telegram interfaces and notifications.",
        "sidebarTitle": "Accounts"
      }
    }, {
      name: "cloud-strategy-in-open-source-and-pro",
      route: "/en/guides/octobot-configuration/cloud-strategy-in-open-source-and-pro",
      frontMatter: {
        "description": "Learn how to use, test and customize OctoBot cloud trading strategies directly from your OctoBot trading bot or self hosted OctoBot.",
        "sidebarTitle": "Cloud Strategy in Open Source and Pro"
      }
    }, {
      name: "custom-profile",
      route: "/en/guides/octobot-configuration/custom-profile",
      frontMatter: {
        "description": "Lean how to create custom configuration profiles on your OctoBot.",
        "sidebarTitle": "Custom Profile"
      }
    }, {
      name: "exchanges",
      route: "/en/guides/octobot-configuration/exchanges",
      frontMatter: {
        "description": "Lean how to configure your exchange accounts for your OctoBot to trade using your exchange funds or simulated money.",
        "sidebarTitle": "Exchanges"
      }
    }, {
      name: "premium-octobot-extension",
      route: "/en/guides/octobot-configuration/premium-octobot-extension",
      frontMatter: {
        "description": "Permanently improve your OctoBot with the Premium OctoBot Extension. Access the Strategy Designer, secure TradingView webhooks and Crypto baskets.",
        "sidebarTitle": "Premium Octobot Extension"
      }
    }, {
      name: "profile-configuration",
      route: "/en/guides/octobot-configuration/profile-configuration",
      frontMatter: {
        "description": "Lean how to configure an OctoBot profile by selecting its trading strategy, evaluators, cryptocurrencies, exchanges and trader setting.",
        "sidebarTitle": "Profile Configuration"
      }
    }, {
      name: "profiles",
      route: "/en/guides/octobot-configuration/profiles",
      frontMatter: {
        "description": "What is an OctoBot profile ? OctoBot is configured using profiles, where your configuration for a specific trading strategy is saved and can be shared.",
        "sidebarTitle": "Profiles"
      }
    }]
  }, {
    name: "octobot-developers-environment",
    route: "/en/guides/octobot-developers-environment",
    children: [{
      data: en_guides_octobot_developers_environment_meta
    }, {
      name: "architecture",
      route: "/en/guides/octobot-developers-environment/architecture",
      frontMatter: {
        "description": "Learn about the OctoBot design philosophy and technical architecture based with speed and scalability in mind using Python and asynchronous programming with asyncio.",
        "sidebarTitle": "Architecture"
      }
    }, {
      name: "environment-variables",
      route: "/en/guides/octobot-developers-environment/environment-variables",
      frontMatter: {
        "description": "Use environment variables to change OctoBot's behavior. Install the latest tentacles, change the web interface IP and port, disable exchange rate limit.",
        "sidebarTitle": "Environment Variables"
      }
    }, {
      name: "github-repositories",
      route: "/en/guides/octobot-developers-environment/github-repositories",
      frontMatter: {
        "description": "Learn about the different OctoBot repositories on GitHub. How the split is done and what is their purpose.",
        "sidebarTitle": "GitHub Repositories"
      }
    }, {
      name: "running-tests",
      route: "/en/guides/octobot-developers-environment/running-tests",
      frontMatter: {
        "description": "Learn how automated tests are working on the OctoBot open source Python repositories using pytest git github actions.",
        "sidebarTitle": "Running Tests"
      }
    }, {
      name: "setup-your-environment",
      route: "/en/guides/octobot-developers-environment/setup-your-environment",
      frontMatter: {
        "description": "Learn how to create your OctoBot developer environment from the open source OctoBot GitHub Python repositories using PyCharm and VS Code.",
        "sidebarTitle": "Setup Your Environment"
      }
    }, {
      name: "tips",
      route: "/en/guides/octobot-developers-environment/tips",
      frontMatter: {
        "description": "Profit from our tips to get you quickly started as an OctoBot developer. Explore the SQLite backtesting files using SQLite browser and test your strategies.",
        "sidebarTitle": "Tips"
      }
    }]
  }, {
    name: "octobot-installation",
    route: "/en/guides/octobot-installation",
    children: [{
      data: en_guides_octobot_installation_meta
    }, {
      name: "cloud-install-octobot-on-digitalocean",
      route: "/en/guides/octobot-installation/cloud-install-octobot-on-digitalocean",
      frontMatter: {
        "description": "Install your OctoBot in the cloud with DigitalOcean in a few minutes and have your OctoBot trading using your strategies 24/7",
        "sidebarTitle": "Cloud Install Octobot on Digitalocean"
      }
    }, {
      name: "install-octobot-on-raspberry-pi",
      route: "/en/guides/octobot-installation/install-octobot-on-raspberry-pi",
      frontMatter: {
        "description": "Learn how to easily install and start your OctoBot on Raspberry Pi using the executable version of the bot.",
        "sidebarTitle": "Install Octobot on Raspberry Pi"
      }
    }, {
      name: "install-octobot-on-your-computer",
      route: "/en/guides/octobot-installation/install-octobot-on-your-computer",
      frontMatter: {
        "description": "Learn how to install your OctoBot on your own computer or server using Docker, the executable or Python and the open source code on github or PIP.",
        "sidebarTitle": "Install Octobot on Your Computer"
      }
    }, {
      name: "install-octobot-with-docker-video",
      route: "/en/guides/octobot-installation/install-octobot-with-docker-video",
      frontMatter: {
        "description": "Learn how to install and start your OctoBot on your own computer or server (Windows, Mac or Linux) using the docker version of the bot.",
        "sidebarTitle": "Install Octobot with Docker Video"
      }
    }, {
      name: "install-octobot-with-executable-video",
      route: "/en/guides/octobot-installation/install-octobot-with-executable-video",
      frontMatter: {
        "description": "Learn how to install and start your OctoBot on your own computer or server (Windows, Mac or Linux) using the executable version of the bot.",
        "sidebarTitle": "Install Octobot with Executable Video"
      }
    }, {
      name: "install-octobot-with-pip",
      route: "/en/guides/octobot-installation/install-octobot-with-pip",
      frontMatter: {
        "description": "Learn how to install and start your OctoBot on your own computer or server (Windows, Mac or Linux) using the PIP (Python index package) version of the bot.",
        "sidebarTitle": "Install Octobot with Pip"
      }
    }, {
      name: "install-octobot-with-python-and-git",
      route: "/en/guides/octobot-installation/install-octobot-with-python-and-git",
      frontMatter: {
        "description": "Learn how to install and start your OctoBot on your own computer or server (Windows, Mac or Linux) using the open source Python code directly from GitHub.",
        "sidebarTitle": "Install Octobot with Python and Git"
      }
    }, {
      name: "octobot-troubleshoot",
      route: "/en/guides/octobot-installation/octobot-troubleshoot",
      frontMatter: {
        "description": "Any question when installing OctoBot ? Check out the most common installation issues on our troubleshoot guide.",
        "sidebarTitle": "Octobot Troubleshoot"
      }
    }]
  }, {
    name: "octobot-interfaces",
    route: "/en/guides/octobot-interfaces",
    children: [{
      data: en_guides_octobot_interfaces_meta
    }, {
      name: "chatgpt",
      route: "/en/guides/octobot-interfaces/chatgpt",
      frontMatter: {
        "description": "Learn how to configure your OctoBot to trade using AI and ChatGPT or another LLM. Understand the costs differences of running it on your local setup.",
        "sidebarTitle": "Chatgpt"
      }
    }, {
      name: "reddit",
      route: "/en/guides/octobot-interfaces/reddit",
      frontMatter: {
        "description": "Learn how to configure your OctoBot to trade using Reddit and watch subreddits to trade according to the Reddit posts.",
        "sidebarTitle": "Reddit"
      }
    }, {
      name: "telegram",
      route: "/en/guides/octobot-interfaces/telegram",
      children: [{
        data: en_guides_octobot_interfaces_telegram_meta
      }, {
        name: "telegram-api",
        route: "/en/guides/octobot-interfaces/telegram/telegram-api",
        frontMatter: {
          "description": "Learn how to configure your OctoBot to trade based on signals from Telegram channels.",
          "sidebarTitle": "Telegram API"
        }
      }]
    }, {
      name: "telegram",
      route: "/en/guides/octobot-interfaces/telegram",
      frontMatter: {
        "description": "Learn how to configure your OctoBot to be accessible directly from Telegram. Command your OctoBot from anywhere directly from Telegram.",
        "sidebarTitle": "Telegram"
      }
    }, {
      name: "tradingview",
      route: "/en/guides/octobot-interfaces/tradingview",
      children: [{
        data: en_guides_octobot_interfaces_tradingview_meta
      }, {
        name: "alert-format",
        route: "/en/guides/octobot-interfaces/tradingview/alert-format",
        frontMatter: {
          "description": "Find everything you need to know to format your TradingView alerts and automate trades on your OctoBot. Buy or sell with market or limit orders, set take profits and stop losses.",
          "sidebarTitle": "Alert Format"
        }
      }, {
        name: "automating-trading-from-a-pine-script-strategy",
        route: "/en/guides/octobot-interfaces/tradingview/automating-trading-from-a-pine-script-strategy",
        frontMatter: {
          "description": "Learn how to make OctoBot trade based on TradingView Pine Script strategies. Send signals from TradingView Pine Script strategies and have your OctoBot instantly trade on your exchange.",
          "sidebarTitle": "Automating Trading from a Pine Script Strategy"
        }
      }, {
        name: "automating-trading-from-an-indicator",
        route: "/en/guides/octobot-interfaces/tradingview/automating-trading-from-an-indicator",
        frontMatter: {
          "description": "Learn how to make OctoBot trade based on TradingView indicators. Send signals from TradingView Pine Script indicator and have your OctoBot instantly trade on your exchange.",
          "sidebarTitle": "Automating Trading from an Indicator"
        }
      }, {
        name: "automating-tradingview-free-email-alerts-with-octobot",
        route: "/en/guides/octobot-interfaces/tradingview/automating-tradingview-free-email-alerts-with-octobot",
        frontMatter: {
          "description": "Learn how to configure your OctoBot to trade using the free TradingView email alerts and automate any TradingView strategy with a free TradingView account.",
          "sidebarTitle": "Automating Tradingview Free Email Alerts with Octobot"
        }
      }, {
        name: "using-a-webhook",
        route: "/en/guides/octobot-interfaces/tradingview/using-a-webhook",
        frontMatter: {
          "description": "Learn how to configure your OctoBot webhooks to trade from TradingView signals using OctoBot cloud, Ngrok or your own setup.",
          "sidebarTitle": "Using a Webhook"
        }
      }]
    }, {
      name: "tradingview",
      route: "/en/guides/octobot-interfaces/tradingview",
      frontMatter: {
        "description": "Learn how to make OctoBot trade based on TradingView alerts. Send signals from TradingView email or webhooks and have your OctoBot trade on your exchange.",
        "sidebarTitle": "Tradingview"
      }
    }, {
      name: "web",
      route: "/en/guides/octobot-interfaces/web",
      frontMatter: {
        "description": "Learn how to configure your OctoBot web interface. Secure it with a password authentication, set it up to have multiple OctoBots on the same computer.",
        "sidebarTitle": "Web"
      }
    }]
  }, {
    name: "octobot-partner-exchanges",
    route: "/en/guides/octobot-partner-exchanges",
    children: [{
      data: en_guides_octobot_partner_exchanges_meta
    }, {
      name: "ascendex",
      route: "/en/guides/octobot-partner-exchanges/ascendex",
      children: [{
        data: en_guides_octobot_partner_exchanges_ascendex_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/ascendex/account-setup",
        frontMatter: {
          "description": "Find our Ascendex registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "ascendex",
      route: "/en/guides/octobot-partner-exchanges/ascendex",
      frontMatter: {
        "description": "Trade on Ascendex spot markets using OctoBot. Use paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Ascendex"
      }
    }, {
      name: "binance",
      route: "/en/guides/octobot-partner-exchanges/binance",
      children: [{
        data: en_guides_octobot_partner_exchanges_binance_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/binance/account-setup",
        frontMatter: {
          "description": "Learn how to connect your Binance account to OctoBot using API keys. Find our Binance registration link and support the OctoBot project.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "binance",
      route: "/en/guides/octobot-partner-exchanges/binance",
      frontMatter: {
        "description": "Trade on Binance spot and future markets using OctoBot. Use paper trading or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Binance"
      }
    }, {
      name: "bingx",
      route: "/en/guides/octobot-partner-exchanges/bingx",
      children: [{
        data: en_guides_octobot_partner_exchanges_bingx_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/bingx/account-setup",
        frontMatter: {
          "description": "Find our BingX registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "bingx",
      route: "/en/guides/octobot-partner-exchanges/bingx",
      frontMatter: {
        "description": "Trade on BingX spot markets using OctoBot. Use paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Bingx"
      }
    }, {
      name: "bitget",
      route: "/en/guides/octobot-partner-exchanges/bitget",
      children: [{
        data: en_guides_octobot_partner_exchanges_bitget_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/bitget/account-setup",
        frontMatter: {
          "description": "Find our Bitget registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "bitget",
      route: "/en/guides/octobot-partner-exchanges/bitget",
      frontMatter: {
        "description": "Trade on Bitget spot markets using OctoBot. Use paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Bitget"
      }
    }, {
      name: "bitmart",
      route: "/en/guides/octobot-partner-exchanges/bitmart",
      children: [{
        data: en_guides_octobot_partner_exchanges_bitmart_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/bitmart/account-setup",
        frontMatter: {
          "description": "Find our BitMart registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "bitmart",
      route: "/en/guides/octobot-partner-exchanges/bitmart",
      frontMatter: {
        "description": "Trade on Bitmart spot markets using OctoBot. Use paper trading, testnet or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Bitmart"
      }
    }, {
      name: "bybit",
      route: "/en/guides/octobot-partner-exchanges/bybit",
      children: [{
        data: en_guides_octobot_partner_exchanges_bybit_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/bybit/account-setup",
        frontMatter: {
          "description": "Find our Bybit registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "bybit",
      route: "/en/guides/octobot-partner-exchanges/bybit",
      frontMatter: {
        "description": "Trade on Bybit spot and future using OctoBot. Use paper trading, testnet or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Bybit"
      }
    }, {
      name: "coinex",
      route: "/en/guides/octobot-partner-exchanges/coinex",
      children: [{
        data: en_guides_octobot_partner_exchanges_coinex_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/coinex/account-setup",
        frontMatter: {
          "description": "Find our CoinEx registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "coinex",
      route: "/en/guides/octobot-partner-exchanges/coinex",
      frontMatter: {
        "description": "Trade on CoinEx spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Coinex"
      }
    }, {
      name: "crypto-com",
      route: "/en/guides/octobot-partner-exchanges/crypto-com",
      children: [{
        data: en_guides_octobot_partner_exchanges_crypto_com_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/crypto-com/account-setup",
        frontMatter: {
          "description": "Find our Crypto.com registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "crypto-com",
      route: "/en/guides/octobot-partner-exchanges/crypto-com",
      frontMatter: {
        "description": "Trade on Crypto.com spot markets using OctoBot. Use paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Crypto Com"
      }
    }, {
      name: "gateio",
      route: "/en/guides/octobot-partner-exchanges/gateio",
      children: [{
        data: en_guides_octobot_partner_exchanges_gateio_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/gateio/account-setup",
        frontMatter: {
          "description": "Find our GateIO registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "gateio",
      route: "/en/guides/octobot-partner-exchanges/gateio",
      frontMatter: {
        "description": "Trade on GateIO spot markets using OctoBot. Use paper trading, testnet or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Gateio"
      }
    }, {
      name: "hollaex",
      route: "/en/guides/octobot-partner-exchanges/hollaex",
      children: [{
        data: en_guides_octobot_partner_exchanges_hollaex_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/hollaex/account-setup",
        frontMatter: {
          "description": "Learn how to connect to your Hollaex based exchange and automate your trading strategies using OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "hollaex",
      route: "/en/guides/octobot-partner-exchanges/hollaex",
      frontMatter: {
        "description": "Trade on HollaEx or any HollaEx-powered spot markets using OctoBot. Use paper trading, funds on exchange or testnet. Enjoy REST and websocket connections.",
        "sidebarTitle": "Hollaex"
      }
    }, {
      name: "htx",
      route: "/en/guides/octobot-partner-exchanges/htx",
      children: [{
        data: en_guides_octobot_partner_exchanges_htx_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/htx/account-setup",
        frontMatter: {
          "description": "Find our HTX (previously Huobi) registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "htx",
      route: "/en/guides/octobot-partner-exchanges/htx",
      frontMatter: {
        "description": "Trade on HTX (Huobi) spot markets using OctoBot. Use paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Htx"
      }
    }, {
      name: "kucoin",
      route: "/en/guides/octobot-partner-exchanges/kucoin",
      children: [{
        data: en_guides_octobot_partner_exchanges_kucoin_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/kucoin/account-setup",
        frontMatter: {
          "description": "Find our Kucoin registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "kucoin",
      route: "/en/guides/octobot-partner-exchanges/kucoin",
      frontMatter: {
        "description": "Trade on Kucoin spot and future using OctoBot. Use paper trading, testnet or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Kucoin"
      }
    }, {
      name: "mexc",
      route: "/en/guides/octobot-partner-exchanges/mexc",
      children: [{
        data: en_guides_octobot_partner_exchanges_mexc_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/mexc/account-setup",
        frontMatter: {
          "description": "Find our MEXC registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "mexc",
      route: "/en/guides/octobot-partner-exchanges/mexc",
      frontMatter: {
        "description": "Trade on MEXC spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections",
        "sidebarTitle": "Mexc"
      }
    }, {
      name: "okcoin",
      route: "/en/guides/octobot-partner-exchanges/okcoin",
      children: [{
        data: en_guides_octobot_partner_exchanges_okcoin_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/okcoin/account-setup",
        frontMatter: {
          "description": "Find our Okcoin registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "okcoin",
      route: "/en/guides/octobot-partner-exchanges/okcoin",
      frontMatter: {
        "description": "Trade on Okcoin spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Okcoin"
      }
    }, {
      name: "okx",
      route: "/en/guides/octobot-partner-exchanges/okx",
      children: [{
        data: en_guides_octobot_partner_exchanges_okx_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/okx/account-setup",
        frontMatter: {
          "description": "Learn how to connect your OKX account to OctoBot using API keys. Find our OKX registration link and support the OctoBot project.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "okx",
      route: "/en/guides/octobot-partner-exchanges/okx",
      frontMatter: {
        "description": "Trade on OKX spot and future using OctoBot. Use paper trading, testnet or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Okx"
      }
    }, {
      name: "phemex",
      route: "/en/guides/octobot-partner-exchanges/phemex",
      children: [{
        data: en_guides_octobot_partner_exchanges_phemex_meta
      }, {
        name: "account-setup",
        route: "/en/guides/octobot-partner-exchanges/phemex/account-setup",
        frontMatter: {
          "description": "Find our Phemex registration link and support the OctoBot project by creating your account from this link to support OctoBot.",
          "sidebarTitle": "Account Setup"
        }
      }]
    }, {
      name: "phemex",
      route: "/en/guides/octobot-partner-exchanges/phemex",
      frontMatter: {
        "description": "Trade on Phemex spot markets using OctoBot. Use simulated, real funds or testnet. Enjoy REST and websocket connections.",
        "sidebarTitle": "Phemex"
      }
    }]
  }, {
    name: "octobot-script-docs",
    route: "/en/guides/octobot-script-docs",
    children: [{
      data: en_guides_octobot_script_docs_meta
    }, {
      name: "creating-trading-orders",
      route: "/en/guides/octobot-script-docs/creating-trading-orders",
      frontMatter: {
        "description": "Learn how to create market, limit, stop loss and trailing orders with python using OctoBot script.",
        "sidebarTitle": "Creating Trading Orders"
      }
    }, {
      name: "fetching-history",
      route: "/en/guides/octobot-script-docs/fetching-history",
      frontMatter: {
        "description": "Learn how to fetch and reuse exchange historical market trading data with python using OctoBot script.",
        "sidebarTitle": "Fetching History"
      }
    }, {
      name: "plotting-anything",
      route: "/en/guides/octobot-script-docs/plotting-anything",
      frontMatter: {
        "description": "Learn how to plot any type of data on your strategy run report with python using OctoBot script.",
        "sidebarTitle": "Plotting Anything"
      }
    }, {
      name: "plotting-indicators",
      route: "/en/guides/octobot-script-docs/plotting-indicators",
      frontMatter: {
        "description": "Learn how to plot technical indicators such as RSI or EMA on your strategy run report with python using OctoBot script.",
        "sidebarTitle": "Plotting Indicators"
      }
    }, {
      name: "run-report",
      route: "/en/guides/octobot-script-docs/run-report",
      frontMatter: {
        "description": "Learn how to create, display and find your strategy run report at the end of each OctoBot script strategy execution.",
        "sidebarTitle": "Run Report"
      }
    }, {
      name: "strategies",
      route: "/en/guides/octobot-script-docs/strategies",
      frontMatter: {
        "description": "Learn how to create, run and backtest your automated trading strategies using a simple TradingView Pine Script like language with OctoBot script.",
        "sidebarTitle": "Strategies"
      }
    }]
  }, {
    name: "octobot-script",
    route: "/en/guides/octobot-script",
    frontMatter: {
      "description": "Harness the power of the OctoBot framework within your own python scripted trading strategies while keeping it as simple as a TradingView Pine Script.",
      "sidebarTitle": "Octobot Script"
    }
  }, {
    name: "octobot-supported-exchanges",
    route: "/en/guides/octobot-supported-exchanges",
    children: [{
      data: en_guides_octobot_supported_exchanges_meta
    }, {
      name: "binanceus",
      route: "/en/guides/octobot-supported-exchanges/binanceus",
      frontMatter: {
        "description": "Trade on Binance.us spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Binanceus"
      }
    }, {
      name: "bitfinex",
      route: "/en/guides/octobot-supported-exchanges/bitfinex",
      frontMatter: {
        "description": "Trade on Bitfinex spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Bitfinex"
      }
    }, {
      name: "bitstamp",
      route: "/en/guides/octobot-supported-exchanges/bitstamp",
      frontMatter: {
        "description": "Trade on Bitstamp spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Bitstamp"
      }
    }, {
      name: "bittrex",
      route: "/en/guides/octobot-supported-exchanges/bittrex",
      frontMatter: {
        "description": "Trade on Bittrex spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies. Enjoy REST and websocket connections.",
        "sidebarTitle": "Bittrex"
      }
    }, {
      name: "bitvavo",
      route: "/en/guides/octobot-supported-exchanges/bitvavo",
      frontMatter: {
        "description": "Trade on Bitvavo spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Bitvavo"
      }
    }, {
      name: "coinbase",
      route: "/en/guides/octobot-supported-exchanges/coinbase",
      frontMatter: {
        "description": "Trade on Coinbase spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Coinbase"
      }
    }, {
      name: "hitbtc",
      route: "/en/guides/octobot-supported-exchanges/hitbtc",
      frontMatter: {
        "description": "Trade on HitBTC spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Hitbtc"
      }
    }, {
      name: "kraken",
      route: "/en/guides/octobot-supported-exchanges/kraken",
      frontMatter: {
        "description": "Trade on Kraken spot markets using OctoBot using simulated funds. Enjoy REST and websocket connections.",
        "sidebarTitle": "Kraken"
      }
    }, {
      name: "poloniex",
      route: "/en/guides/octobot-supported-exchanges/poloniex",
      frontMatter: {
        "description": "Trade on Poloniex spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Poloniex"
      }
    }, {
      name: "wavesexchange",
      route: "/en/guides/octobot-supported-exchanges/wavesexchange",
      frontMatter: {
        "description": "Trade on Wavesexchange spot markets using OctoBot. Use risk free paper trading or funds on exchange and profit from your strategies.",
        "sidebarTitle": "Wavesexchange"
      }
    }]
  }, {
    name: "octobot-tentacles-development",
    route: "/en/guides/octobot-tentacles-development",
    children: [{
      data: en_guides_octobot_tentacles_development_meta
    }, {
      name: "create-a-tentacle-package",
      route: "/en/guides/octobot-tentacles-development/create-a-tentacle-package",
      frontMatter: {
        "description": "Learn how to bundle your custom OctoBot tentacles in a tentacle package, enable configuration via user inputs, add associated docs and share it.",
        "sidebarTitle": "Create a Tentacle Package"
      }
    }, {
      name: "create-a-tentacle",
      route: "/en/guides/octobot-tentacles-development/create-a-tentacle",
      frontMatter: {
        "description": "Create your own OctoBot tentacles (aka extensions). Add technical and social analysis tools, improve the web, telegram interfaces, fix exchange connections.",
        "sidebarTitle": "Create a Tentacle"
      }
    }, {
      name: "customize-your-octobot",
      route: "/en/guides/octobot-tentacles-development/customize-your-octobot",
      frontMatter: {
        "description": "Lean how to deeply customize your OctoBot by installing and creating and sharing custom tentacles and tentacle packages.",
        "sidebarTitle": "Customize Your Octobot"
      }
    }]
  }, {
    name: "octobot-trading-modes",
    route: "/en/guides/octobot-trading-modes",
    children: [{
      data: en_guides_octobot_trading_modes_meta
    }, {
      name: "chatgpt-trading",
      route: "/en/guides/octobot-trading-modes/chatgpt-trading",
      frontMatter: {
        "description": "Learn how to use ChatGPT prediction within OctoBot to boost your crypto trading strategies with real-time AI market views and predictions.",
        "sidebarTitle": "Chatgpt Trading"
      }
    }, {
      name: "daily-trading-mode",
      route: "/en/guides/octobot-trading-modes/daily-trading-mode",
      frontMatter: {
        "description": "Using the Daily Trading Mode, you use the most flexible trading strategy on OctoBot. Trade SPOT and futures using any technical, social or AI evaluator.",
        "sidebarTitle": "Daily Trading Mode"
      }
    }, {
      name: "dca-trading-mode",
      route: "/en/guides/octobot-trading-modes/dca-trading-mode",
      frontMatter: {
        "description": "Optimize your Dollar cost Averaging strategy (DCA) using the OctoBot DCA Trading Mode. Automate DCA on technical indicators or on a regular basis.",
        "sidebarTitle": "Dca Trading Mode"
      }
    }, {
      name: "dip-analyser-trading-mode",
      route: "/en/guides/octobot-trading-modes/dip-analyser-trading-mode",
      frontMatter: {
        "description": "Profit from local bottoms and multiple take profits using the Dip Analyser Trading Mode on OctoBot to trade SPOT or futures markets.",
        "sidebarTitle": "Dip Analyser Trading Mode"
      }
    }, {
      name: "grid-trading-mode",
      route: "/en/guides/octobot-trading-modes/grid-trading-mode",
      frontMatter: {
        "description": "Easily profit from sideway markets by maintaining a grid-like set of buy and sell orders using the Grid Trading Mode.",
        "sidebarTitle": "Grid Trading Mode"
      }
    }, {
      name: "index-trading-mode",
      route: "/en/guides/octobot-trading-modes/index-trading-mode",
      frontMatter: {
        "description": "Invest in the multiple crypto at the same time and create your own crypto index using the Index trading mode.",
        "sidebarTitle": "Index Trading Mode"
      }
    }, {
      name: "order-amount-syntax",
      route: "/en/guides/octobot-trading-modes/order-amount-syntax",
      frontMatter: {
        "description": "Configure your OctoBot orders sizes using the many available options. Size your orders based your a percent of your portfolio, a scaling or even a static amount.",
        "sidebarTitle": "Order Amount Syntax"
      }
    }, {
      name: "order-price-syntax",
      route: "/en/guides/octobot-trading-modes/order-price-syntax",
      frontMatter: {
        "description": "Configure your OctoBot orders prices using a percent of the current price, static price or a difference from the current price.",
        "sidebarTitle": "Order Price Syntax"
      }
    }, {
      name: "staggered-orders-trading-mode",
      route: "/en/guides/octobot-trading-modes/staggered-orders-trading-mode",
      frontMatter: {
        "description": "Profit from sideway markets by maintaining a grid-like set of buy and sell orders with advanced configuration using the Staggered Orders Trading Mode.",
        "sidebarTitle": "Staggered Orders Trading Mode"
      }
    }, {
      name: "trading-modes",
      route: "/en/guides/octobot-trading-modes/trading-modes",
      frontMatter: {
        "description": "Discover how trading strategies work in OctoBot and find the major trading modes based on DCA, grid trading, AI and TradingView.",
        "sidebarTitle": "Trading Modes"
      }
    }, {
      name: "tradingview-trading-mode",
      route: "/en/guides/octobot-trading-modes/tradingview-trading-mode",
      frontMatter: {
        "description": "Easily automate your TradingView strategies and indicators trades on exchange using the TradingView Trading Mode.",
        "sidebarTitle": "Tradingview Trading Mode"
      }
    }]
  }, {
    name: "octobot-usage",
    route: "/en/guides/octobot-usage",
    children: [{
      data: en_guides_octobot_usage_meta
    }, {
      name: "backtesting",
      route: "/en/guides/octobot-usage/backtesting",
      frontMatter: {
        "description": "Use backtesting to risk-free test and optimize your OctoBot trading strategies. Evaluate your strategy performances on the past days, weeks, months or years.",
        "sidebarTitle": "Backtesting"
      }
    }, {
      name: "frequently-asked-questions-faq",
      route: "/en/guides/octobot-usage/frequently-asked-questions-faq",
      frontMatter: {
        "description": "Any question when using OctoBot ? Check out the most common questions from the OctoBot community and find the detailed answers on our FAQ.",
        "sidebarTitle": "Frequently Asked Questions Faq"
      }
    }, {
      name: "futures-trading-with-octobot",
      route: "/en/guides/octobot-usage/futures-trading-with-octobot",
      frontMatter: {
        "description": "OctoBot can be used to trade Futures using configured strategies TradingView on Binance, Bybit, Kucoin and OKX.",
        "sidebarTitle": "Futures Trading with Octobot"
      }
    }, {
      name: "having-multiple-octobots-on-one-computer",
      route: "/en/guides/octobot-usage/having-multiple-octobots-on-one-computer",
      frontMatter: {
        "description": "Guide on how to have multiple OctoBots running on the same computer. Use multiple accounts on the same exchange and invest using different strategies.",
        "sidebarTitle": "Having Multiple Octobots on One Computer"
      }
    }, {
      name: "simulator",
      route: "/en/guides/octobot-usage/simulator",
      frontMatter: {
        "description": "You prefer trading with simulated money before using your real funds ? Use OctoBot trading simulator to run any strategy using paper trading.",
        "sidebarTitle": "Simulator"
      }
    }, {
      name: "strategy-designer",
      route: "/en/guides/octobot-usage/strategy-designer",
      frontMatter: {
        "description": "Use the best tool to create, test and optimize your trading strategy. The Strategy Designer enables you to deeply analyse and improve your strategies.",
        "sidebarTitle": "Strategy Designer"
      }
    }, {
      name: "understanding-profitability",
      route: "/en/guides/octobot-usage/understanding-profitability",
      frontMatter: {
        "description": "Having a hard time understanding how profitability and Profit and loss (PNL) work in OctoBot or how to reset it ? Check out our guide.",
        "sidebarTitle": "Understanding Profitability"
      }
    }]
  }, {
    name: "octobot",
    route: "/en/guides/octobot",
    frontMatter: {
      "description": "Any question on OctoBot the open source trading robot ? Here are the guides on how to install your bot locally or using a cloud and how to trade using Telegram, ChatGPT or TradingView.",
      "sidebarTitle": "Octobot"
    }
  }]
}, {
  name: "investing",
  route: "/en/investing",
  children: [{
    data: en_investing_meta
  }, {
    name: "connect-your-binance-account-to-octobot",
    route: "/en/investing/connect-your-binance-account-to-octobot",
    frontMatter: {
      "description": "Step by step guide on how to securely use your Binance account with OctoBot cloud and profit from automated crypto investments.",
      "sidebarTitle": "Connect Your Binance Account to Octobot"
    }
  }, {
    name: "connect-your-coinbase-account-to-octobot",
    route: "/en/investing/connect-your-coinbase-account-to-octobot",
    frontMatter: {
      "description": "Step by step guide on how to securely use your Coinbase account with OctoBot cloud and profit from automated crypto investments.",
      "sidebarTitle": "Connect Your Coinbase Account to Octobot"
    }
  }, {
    name: "connect-your-kucoin-account-to-octobot",
    route: "/en/investing/connect-your-kucoin-account-to-octobot",
    frontMatter: {
      "description": "Step by step guide on how to securely use your Kucoin account with OctoBot cloud and profit from automated crypto investments.",
      "sidebarTitle": "Connect Your Kucoin Account to Octobot"
    }
  }, {
    name: "find-your-strategy",
    route: "/en/investing/find-your-strategy",
    frontMatter: {
      "description": "Learn how to explore, compare and find or create the best trading strategy for you on OctoBot cloud.",
      "sidebarTitle": "Find Your Strategy"
    }
  }, {
    name: "fine-tune-your-octobots",
    route: "/en/investing/fine-tune-your-octobots",
    frontMatter: {
      "description": "Fine tune your OctoBot by easily creating, replacing and cancelling orders and rebalancing your portfolio directly from your OctoBots.",
      "sidebarTitle": "Fine Tune Your Octobots"
    }
  }, {
    name: "follow-your-profits",
    route: "/en/investing/follow-your-profits",
    frontMatter: {
      "description": "Learn how to quickly and easily follow profits and activity of your running and stopped OctoBot trading robots on OctoBot cloud.",
      "sidebarTitle": "Follow Your Profits"
    }
  }, {
    name: "having-multiple-octobot-strategies",
    route: "/en/investing/having-multiple-octobot-strategies",
    frontMatter: {
      "description": "Learn how to run multiple trading strategies to optimize your gains and reduce risk. Use OctoBot cloud to run many strategies for free.",
      "sidebarTitle": "Having Multiple Octobot Strategies"
    }
  }, {
    name: "how-to-automate-any-tradingview-strategy-on-octobot-cloud",
    route: "/en/investing/how-to-automate-any-tradingview-strategy-on-octobot-cloud",
    frontMatter: {
      "description": "Use TradingView strategies to automate your trading on your crypto exchange account. Automate ready-made or custom Pine Script strategies.",
      "sidebarTitle": "How to Automate Any Tradingview Strategy on Octobot Cloud"
    }
  }, {
    name: "introduction",
    route: "/en/investing/introduction",
    frontMatter: {
      "description": "Any question on OctoBot cloud investments ? How to use a trading strategy ? How to follow your investment ? It is all on the guides.",
      "sidebarTitle": "Introduction"
    }
  }, {
    name: "invest-with-your-strategy",
    route: "/en/investing/invest-with-your-strategy",
    frontMatter: {
      "description": "Learn how to quickly and easily start your investment on OctoBot cloud.",
      "sidebarTitle": "Invest with Your Strategy"
    }
  }, {
    name: "investor-faq",
    route: "/en/investing/investor-faq",
    frontMatter: {
      "description": "Any question on OctoBot cloud ? Here are the frequently asked questions and their answers.",
      "sidebarTitle": "Investor Faq"
    }
  }, {
    name: "paper-trading-a-strategy",
    route: "/en/investing/paper-trading-a-strategy",
    frontMatter: {
      "description": "Learn how to use paper trading to test strategies and crypto baskets live and risk free with OctoBot cloud.",
      "sidebarTitle": "Paper Trading a Strategy"
    }
  }, {
    name: "pay-with-crypto",
    route: "/en/investing/pay-with-crypto",
    frontMatter: {
      "description": "Step-by-step guide on paying for your OctoBot Cloud subscription with cryptocurrency",
      "sidebarTitle": "Pay with Crypto"
    }
  }, {
    name: "stop-a-strategy",
    route: "/en/investing/stop-a-strategy",
    frontMatter: {
      "description": "Learn how to quickly and easily stop and restart your running investment strategy on OctoBot cloud.",
      "sidebarTitle": "Stop a Strategy"
    }
  }, {
    name: "tradingview-alerts-automation",
    route: "/en/investing/tradingview-alerts-automation",
    frontMatter: {
      "description": "Learn how to automate your TradingView alerts coming from price events, indicators or Pine Script strategies using paper or real trading on any exchange.",
      "sidebarTitle": "Tradingview Alerts Automation"
    }
  }, {
    name: "tradingview-automated-trading",
    route: "/en/investing/tradingview-automated-trading",
    frontMatter: {
      "description": "Easily automate your trades using TradingView with OctoBot. Enjoy unlimited alerts on price, indicator and Pine Script strategies with paper and real trading.",
      "sidebarTitle": "Tradingview Automated Trading"
    }
  }, {
    name: "tradingview-strategies-tutorials",
    route: "/en/investing/tradingview-strategies-tutorials",
    children: [{
      data: en_investing_tradingview_strategies_tutorials_meta
    }, {
      name: "automating-a-tradingview-death-and-golden-cross-strategy",
      route: "/en/investing/tradingview-strategies-tutorials/automating-a-tradingview-death-and-golden-cross-strategy",
      frontMatter: {
        "description": "Learn to automate a Bitcoin Death and Golden Cross Strategy using TradingView alerts and OctoBot with paper or real trading on any exchange.",
        "sidebarTitle": "Automating a Tradingview Death and Golden Cross Strategy"
      }
    }, {
      name: "bull-market-strategy-from-tradingview-using-rsi-with-video",
      route: "/en/investing/tradingview-strategies-tutorials/bull-market-strategy-from-tradingview-using-rsi-with-video",
      frontMatter: {
        "description": "Optimize your Bull Market gains: profit from altcoin moonshots and trade before market trend switches using RSI. Discover this strategy with our video tutorial",
        "sidebarTitle": "Bull Market Strategy from Tradingview Using Rsi with Video"
      }
    }]
  }, {
    name: "tradingview-trading-tutorial",
    route: "/en/investing/tradingview-trading-tutorial",
    frontMatter: {
      "description": "Simple step by step tutorial to create your TradingView OctoBot and trade on TradingView alerts using paper or real trading on any cryptocurrency exchange.",
      "sidebarTitle": "Tradingview Trading Tutorial"
    }
  }, {
    name: "what-is-an-exchange-api-key",
    route: "/en/investing/what-is-an-exchange-api-key",
    frontMatter: {
      "description": "Wondering what an exchange API Key is and why you should use it with a trading software ? Here is the simple explanation.",
      "sidebarTitle": "What Is an Exchange API Key"
    }
  }]
}];